<template>
  <div>
    <h4 class="page-title">观看设置</h4>
    <a-row class="rows">
      <a-col class="left required" span="3">免费试看设置</a-col>
      <a-col class="right" span="20">
        <a-radio-group class="radioGroup" v-model="formData.freeTrialType">
          <a-radio :disabled="userStudy == '1'" value="1" style="line-height:32px;">不设置免费试看</a-radio>
          <!-- <a-radio :disabled="userStudy == '1'" value="2" style="line-height:32px;">
            <span>可免费试看</span>
            <a-input-number :precision="0" :min="1" :disabled="userStudy == '1'" style="width:100px;margin:0 8px;" v-if="formData.freeTrialType=='2'" v-model="formData.freeTrialMinute"/>
            <span v-else> {{formData.freeTrialMinute?formData.freeTrialMinute:'-'}} </span>
            <span>分钟</span>
          </a-radio> -->
          <a-radio :disabled="userStudy == '1'" value="3" style="display:block;line-height:32px;">
            <span>此章节可试看</span>
            <a v-if="userStudy != '1' && formData.freeTrialType == '3'" style="margin-left:8px;" @click="onAddChapter()">添加章节</a>
          </a-radio>
        </a-radio-group>
      </a-col>
    </a-row>
    <a-row class="rows" style="margin-top:0;">
      <a-col class="right" span="20" :offset="4">
        <div class="tryChapterList">
          <div v-for="(item,index) in getTryChapter()" :key="'tryChapterList'+index">
            <span>{{item.sectionName}}</span>
            <a style="margin-left: 30px;" @click="removeTryChapter(item)">删除</a>
          </div>
        </div>
        <div style="font-size:14px;padding-left:24px;" v-if="getTryChapter() && getTryChapter().length > 0">
          <span>以上章节可免费试看</span>
          <a-input-number :precision="0" :min="1" style="width:100px;margin:0 8px;" v-if="formData.freeTrialType=='3'" v-model="formData.freeTrialMinute"/>
          <span v-else> {{formData.freeTrialMinute?formData.freeTrialMinute:'-'}} </span>
          <span>分钟</span>
        </div>
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left required" span="3">课程观看设置</a-col>
      <a-col class="right" span="20">
        <div>
          <span class="fontSize">首次观看：</span>
          <a-checkbox :disabled="userStudy == '1'" v-model="formData.firstWatchType">可拖拽</a-checkbox>
          <a-checkbox :disabled="userStudy == '1'" v-model="formData.firstSpeedType">可倍速</a-checkbox>
        </div>
        <div>
          <span class="fontSize">再次观看：</span>
            <a-checkbox :disabled="userStudy == '1'" v-model="formData.againWatch">可拖拽</a-checkbox>
            <a-checkbox :disabled="userStudy == '1'" v-model="formData.againSpeed">可倍速</a-checkbox>
        </div>
        <div style="padding:8px 0;">
          <a-checkbox :disabled="userStudy == '1'" v-model="formData.watchByCatalogueOrder">必须按目录顺序进行观看</a-checkbox>
        </div>
        <div style="display: flex;">
          <span class="fontSize">观看条件：</span>
          <div class="fontSize">
            <a-checkbox :disabled="userStudy == '1'" v-model="viewCondition1">必须通过以下考试</a-checkbox>
            <a v-if="viewCondition1 && userStudy != '1'" style="margin-left:8px;" @click="$refs.Modal.open({
              title: '选择考试',
              width: '700px',
              type: 'SelectiveExam',
              checked: getCourseWatchLimitList(formData.courseWatchLimitList,1)
            })">选择考试</a>
            <div style="padding-left:54px;">
              <!-- 观看条件类型1.必须通过以下考试 2.必须获得以下考试证书 -->
              <div v-for="(item,index) in getCourseWatchLimitList(formData.courseWatchLimitList,1)" :key="'ExamNameList'+index">
                <span>{{item.name}}</span>
                <a v-if="userStudy != '1'" style="margin-left: 30px;" @click="removeCourseWatchLimitList(item.dataId,1)">删除</a>
              </div>
            </div>
            <a-checkbox :disabled="userStudy == '1'" v-model="viewCondition2">必须获得以下考试证书</a-checkbox>
            <a v-if="viewCondition2 && userStudy != '1'" style="margin-left:8px;" @click="$refs.Modal.open({
              title: '选择证书',
              width: '700px',
              type: 'SelectiveCertificate',
              checked: getCourseWatchLimitList(formData.courseWatchLimitList,2)
            })">选择证书</a>
            <div style="padding-left:54px;">
              <div v-for="(item,index) in getCourseWatchLimitList(formData.courseWatchLimitList,2)" :key="'CertificateNameList'+index">
                <span>{{item.name}}</span>
                <a v-if="userStudy != '1'" style="margin-left: 30px;" @click="removeCourseWatchLimitList(item.dataId,2)">删除</a>
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-row class="rows" v-if="!isOffline">
      <a-col class="left required" span="3">课程有效期设置</a-col>
      <a-col class="right" span="20">
        <div class="fontSize">
          <span>有效期设置：</span>
          <a-input-number :precision="0" :min="1" v-model="formData.validDays" :disabled="formData.validDayType == '2' || userStudy == '1'" style="width:120px;margin-left:8px;"/>
          <span style="margin-left:8px;">天；</span>
          <a-checkbox :disabled="userStudy == '1'" style="margin-left:8px;line-height:32px;" :checked="formData.validDayType == '2'" @change="changeTermValidity">长期</a-checkbox>
        </div>
        <div class="fontSize" style="margin-top:10px;" v-if="formData.validDayType != '2'">
          <span>有效期开始计算节点：</span>
          <a-radio-group class="radioGroup" v-model="formData.validStartType">
            <template v-if="SectionTestLength > 0">
              <a-radio value="1" :disabled="userStudy == '1'">
                <span>开始做第</span>
                <a-input-number :precision="0" :min="1" :disabled="userStudy == '1' || formData.validStartType != '1'" v-model="formData.validStartSectionId" style="width:120px;margin-left:8px;"/>
                <span style="margin-left:8px;">节小节自测起</span>
              </a-radio>
            </template>
            <template v-if="setOpenClass=='1' && openTimeList && openTimeList.length > 0">
              <a-radio value="2" :disabled="userStudy == '1'">课程开班时间起</a-radio>
            </template>
            <!-- <template v-if="formData.freeTrialType != '1'">
              <a-radio value="3" :disabled="userStudy == '1'">课程免费试看部分结束时</a-radio>
            </template> -->
            <a-radio value="4" :disabled="userStudy == '1'">课程支付成功开始算起</a-radio>
            <template>
              <a-radio value="5" :disabled="userStudy == '1'">观看此章节视频开始算起</a-radio>
              <a v-if="formData.validStartType == '5' && userStudy !== '1'" style="margin-left:8px;" @click="onAddChapterValidDay()">选择章节</a>
              {{validStartSectionName}}
            </template>
          </a-radio-group>
        </div>
      </a-col>
    </a-row>
    <a-row class="rows" v-if="formData.validDayType != '2' && !isOffline">
      <a-col class="left required" span="3">课程延期设置</a-col>
      <a-col class="right" span="20">
        <a-radio-group class="radioGroup" v-model="formData.setDelay">
          <a-radio value="0" :disabled="userStudy == '1'">无需设置课程延期</a-radio>
          <a-radio value="1" :disabled="userStudy == '1'">需要设置课程延期</a-radio>
        </a-radio-group>
        <a-button type="primary" v-if="formData.setDelay == '1' && userStudy != '1'" @click="addCourseDelayList()">新增</a-button>
      </a-col>
    </a-row>
    <a-row class="rows" v-if="formData.setDelay == '1' && formData.validDayType != '2'" style="margin-top:0;">
      <a-col class="right" span="20" offset="4">
        <div class="fontSize" style="margin-top:10px;" v-for="(item,index) in formData.courseDelayList" :key="'courseDelayList'+item.uid">
          <span>第{{index+1}}次延期：</span>
          <a-input-number :precision="0" :min="1" :disabled="userStudy == '1'" style="width:120px;margin-left:8px;" v-model="item.days"/>
          <span style="margin-left:8px;">天，</span>
          <a-input-number :precision="2" :min="0" :disabled="userStudy == '1'" style="width:120px;margin-left:8px;" v-model="item.price"/>
          <span style="margin-left:8px;">元，赠送</span>
          <a-input-number :precision="0" :min="0" :disabled="userStudy == '1'" style="width:120px;margin-left:8px;" v-model="item.examNumber"/>
          <span style="margin-left:8px;">次考试机会</span>
          <template v-if="!(index < formData.courseDelayList.length - 1)">
            <span>，</span>
            <a-checkbox :disabled="userStudy == '1'" :defaultChecked="item.afterIsSame==1" @change="changeAfterIsSame($event,item)">多次延期同此</a-checkbox>
            <a v-if="userStudy != '1'" @click="removeCourseDelayList(item.uid)">删除</a>
          </template>
        </div>
      </a-col>
    </a-row>
    <a-row class="rows" v-if="!isOffline">
      <a-col class="left" span="3">学习证明名称</a-col>
      <a-col class="right" span="12">
        <a-input v-model="formData.studyCertifName" placeholder="请输入学习证明名称" />
      </a-col>
    </a-row>
    <!-- 添加学习证明名称隐藏证书设置模块 -->
    <a-row class="rows" v-if="!isOffline && false">
      <a-col class="left required" span="3">课程证书设置</a-col>
      <a-col class="right" span="20">
        <a-radio-group class="radioGroup" v-model="formData.setCourseCertificate">
          <a-radio :disabled="userStudy == '1'" value="0">课程无证书</a-radio>
          <a-radio :disabled="userStudy == '1'" value="1">课程有证书</a-radio>
          <a-radio :disabled="userStudy == '1'" value="2">学完 <a-input-number  :min="1" v-model="formData.watchCount" :disabled="formData.setCourseCertificate != '2' || userStudy == '1'" style="width:120px;margin-left:8px;"/>个章节可以获得
          </a-radio>
        </a-radio-group>
        <div class="fontSize" v-if="formData.setCourseCertificate == '1'">
          <span>学完以下章节可获得证书：</span>
          <a-button type="primary" v-show="userStudy != '1'" @click="addCourseCertificate()">新增</a-button>
          <div v-for="(item, index) in formData.courseCertificateList" :key="'courseCertificateList'+ index" style="margin-top:10px;">
            <a-tree-select
              :disabled="userStudy == '1'"
              v-model="item.sectionId"
              style="width:240px;margin-left:8px;"
              tree-default-expand-all
              :tree-data="funFilterVideo(formData.courseCatalogueList)"
              :replaceFields="{children:'childList', title:'sectionName', key:'uid', value:'sectionId' }"
              placeholder="请选择章节"
            >
              <a-icon slot="suffixIcon" type="caret-down"/>
            </a-tree-select>
            <span style="margin-left:8px;">，关联证书</span>
            <a-select style="width:450px;margin-left:8px;" :disabled="userStudy == '1'" v-model="item.certifId" placeholder="请选择关联证书">
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option :value="item.tempId" v-for="item in curriculumCertifTempList" :key="'curriculumCertifTempList'+item.tempId" :title="item.name">{{item.name}}</a-select-option>
            </a-select>
            <a v-if="userStudy != '1'" style="margin-left:5px;" @click="removeCourseCertificate(item)">删除</a>
          </div>
        </div>
        <div class="fontSize" v-if="formData.setCourseCertificate == '2'">
          <a-select style="width:240px;margin-left:8px;" :disabled="userStudy == '1'" v-model="formData.courseCertifId" placeholder="请选择关联证书">
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option :value="item.tempId" v-for="item in curriculumCertifTempList" :key="'curriculumCertifTempList'+item.tempId">{{item.name}}</a-select-option>
          </a-select>
        </div>
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left required" span="3">课程考试设置</a-col>
      <a-col class="right" span="20">
        <a-radio-group class="radioGroup" v-model="formData.setExam">
          <a-radio :disabled="userStudy == '1'" value="0">无需考试</a-radio>
          <a-radio :disabled="userStudy == '1'" value="1">需要考试</a-radio>
        </a-radio-group>
        <br/><br/>
        <template v-if="formData.setExam=='1'">
          <a-select :disabled="userStudy == '1'" style="width:450px;" v-model="formData.examCertifTempId" placeholder="请选择考试证书模板">
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option :value="item.tempId" v-for="item in examCertifTempList" :key="'examCertifTempList'+item.tempId" :title="item.name">{{item.name}}</a-select-option>
          </a-select>
          <template v-if="formData.examCertifTempId">
            <div style="border:1px solid #ccc;display:inline-block;vertical-align: text-top;margin: -5px 0 0 10px;">
              <img :src="examCertifTempImage" style="max-height:300px;max-width:300px;min-height:100px;min-width:100px;" alt="">
            </div>
          </template>
        </template>
      </a-col>
    </a-row>
    <!-- <a-row class="rows" v-if="!isOffline && formData.setSectionTest==1 &&  SectionTestLength > 0"> -->
    <a-row class="rows">
      <a-col class="left required" span="3">小节自测设置</a-col>
      <a-col class="right" span="20">
        <!-- <a-radio-group class="radioGroup" v-model="formData.setSectionTest">
          <a-radio :disabled="userStudy == '1'" value="0">无小节自测</a-radio>
          <a-radio :disabled="userStudy == '1'" value="1"> -->
            <span>该课程下每节小节自测正确率达到</span>
            <a-input-number :precision="0" :min="0" style="width:100px;margin-left:8px;" v-model="formData.sectionTestCorrectRate"/>
            <span style="margin-left:8px;">%，即可通过</span>
          <!-- </a-radio>
        </a-radio-group> -->
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col span="20" offset="4">
        <a-button
          v-if="$route.query.status == 1"
          @click="onStorageClick"
          :loading="loadingBtn"
          type="primary"
          style="margin-right: 30px"
          >暂存</a-button
        >
        <a-button
          @click="onSaveClick"
          :loading="loadingBtn"
          >保存</a-button
        >
      </a-col>
    </a-row>
    <!-- 弹出窗 -->
    <Modal ref="Modal" @ok="onModal"/>
    <!-- 弹窗 - 选择商品 -->
    <ModalProduct ref="ModalProduct" @ok="onModalProduct"/>
  </div>
</template>

<script>
import config from '../CourseDetails/config.vue'
import { uuid } from "@/unit/fun.js";

export default {
  mixins: [ config ],
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      loadingBtn: false,
      isOffline: false,
      userStudy: '0', // 是否有学员开始学习1.是 0.否
      formData:{
        courseId:0, // 课程id
        freeTrialType: '1', // 免费试看类型1.不设置免费试看 2.只第一节可免费试看 3.多章节可试看
        freeTrialMinute: '', // 免费试看分钟数
        firstWatchType: false, // 首次观看是否可拖拽1.是 0.否
        againWatch: true, // 再次观看是否可拖拽1.是 0.否
        firstSpeedType: false, // 首次观看是否可倍速1.是 0.否
        againSpeed: true, // 再次观看是否可倍速1.是 0.否
        watchByCatalogueOrder: true, // 是否必须按目录顺序进行观看1.是 0.否
        offlineSameAddr: '1', // 线下培训场次是否在同一地点1.是 0.否
        setOpenClass: '0', // 是否设置开班时间1.是 0.否
        courseOpenTimeList: [], // 课程开班时间,示例值([ { "type": 1, "className": "第一期", "startTime": "2022-03-15", "endTime": "2022-04-15", "number": 50, "province": "100000", "city": "100000", "district": "100000", "address": "怡和阳光大厦" } ])	
        validDayType: '1', // 有效期类型1.固定期限2.长期
        validDays: '', // 有效期天数
        validStartType: '0', // 	有效期开始计算节点1.从做某小节自测算起 2.课程开班时间算起 3.课程免费试看部分结束时开始算起 4.课程支付成功开始算起 5.观看章节视频开始算起
        validStartSectionId: '',// 有效期开始计算节点为1时，此为某章节目录id
        setDelay: '0', // 是否设置课程延期1.是 0.否
        courseDelayList: [], // 课程延期设置,示例值([ { "afterIsSame": 0, "days": 0, "examNumber": 0, "price": 0, "sort": 0 } ])
        courseWatchLimitList: [], // 课程观看条件限制
        courseFreeTrialSectionList: [], //免费试看章节
        setExam: '0', // 是否需要考试1.是 0.否
        examCertifTempId: undefined, // 考试证书模板id
        setCourseCertificate: '0', // 是否有课程证书1.是 0.否
        courseCertificateList: [], // 课程证书
        courseCertifId: null, // 课程模板id
        watchCount: 1, // 课程数量
        setSectionTest: '1', // 是否设置小节自测1.是 0.否
        sectionTestCorrectRate: '100', // 小节自测正确率
        watchLimitType: null, // 课程观看限制1.必须通过以下课程的考试 2.拥有以下课程的考试证书 3.二者皆可
        courseCatalogueList: [], // 课程章节目录
        saveType:0, // 保存类型(0-未填写，1-暂存，2-提交)
        studyCertifName: '', // 学习证明名称
      },

      examCertifTempList: [], //证书模板

      // 观看此章节视频开始算起
      validStartSectionName: '', //章节名称
      courseCatalogueList:[], //章节目录

      setOpenClass: '0',
      openTimeList: [],

      saveType: false, // 提交状态  ture: 提交过 false:未提交过
    };
  },
  // 事件处理器
  methods: {
    // 暂存检查Form表单并返回object
    fun_StorageVerific(){
      const data = this.formData
      let obj = new Object()
      let arr = []

      // 课程id
      obj.courseId = data.courseId

      // 免费试看设置
      obj.freeTrialType = data.freeTrialType

      function getTryChapter(){
        let list = []
        function fors(arr){
          arr.forEach((item)=>{
            if(item.childList && item.childList.length>0){
              fors(item.childList)
            }
            if(item.freeTrial == 1){
              list.push(item)
            }
          })
        }
        fors(data.courseCatalogueList)
        return list
      }
      if(data.freeTrialType == '3' && !(getTryChapter() && getTryChapter().length > 0)){ // 当选择免费试看，不允许不选试看章节
        return Promise.reject({msg:'请设置免费试看'})
      }else if(data.freeTrialType == '2'){ // 免费试看分钟为第一章节
        let is = false
        function fors(arr){
          arr.forEach((item)=>{
            if(item.videoFileId && !is){
              item.freeTrial = 1
              is = true
            }
            if(item.childList && item.childList.length>0){
              fors(item.childList)
            }
          })
          return arr
        }
        data.courseCatalogueList = fors(data.courseCatalogueList)
      }


      if(data.freeTrialType == '3'){
        let freeSelctionList = this.getTryChapter()
        obj.courseFreeTrialSectionList = []
        freeSelctionList.forEach(element => {
          obj.courseFreeTrialSectionList.push({
              courseId: data.courseId,
              sectionId: element.sectionId,
            }
          )
        });
      }

      // 免费试看分钟数
      obj.freeTrialMinute = data.freeTrialMinute

      // 课程观看设置 - 首次拖拽
      obj.firstWatchType = data.firstWatchType ? '1' : '0'

      // 课程观看设置 - 再次拖拽
      obj.againWatch = data.againWatch ? '1' : '0'

      // 课程观看设置 - 首次倍速
      obj.firstSpeedType = data.firstSpeedType ? '1' : '0'

      // 课程观看设置 - 再次倍速
      obj.againSpeed = data.againSpeed ? '1' : '0'
      
      // 课程观看设置 - 是否按目录顺序进行观看
      obj.watchByCatalogueOrder = data.watchByCatalogueOrder ? '1' : '0'

      // 课程观看设置 - 观看条件
      obj.watchLimitType = data.watchLimitType

      // 课程观看设置 - 观看条件限制
      obj.courseWatchLimitList = data.courseWatchLimitList

      if(!this.isOffline){ // 线上课程
        // 课程有效期设置 - 固定期限/长期
        obj.validDayType = data.validDayType

        // 课程有效期设置 - 天数
        if(data.validDayType == '1'){
          obj.validDays = data.validDays

          // 课程有效期设置 - 有效期开始计算节点
          obj.validStartType = data.validStartType

          // 课程有效期设置 - 章节开始计算节点
          if(data.validStartType == '5' && !data.validStartSectionId){
            return Promise.reject({msg:'请设置课程有效期计算开始章节'})
          }

          // 课程有效期设置 - 开始做第n节小节自测起
          if(data.validStartType == '1' || data.validStartType == '5'){
            obj.validStartSectionId = data.validStartSectionId
          }
        }

        // 课程延期设置
        obj.setDelay = data.setDelay

        // 课程延期设置 - 需要设置课程延期
        if(data.setDelay == '1'){
          arr = []
          data.courseDelayList.forEach((item,index)=>{
            item.sort = index+1
            arr.push(item)
          })
          obj.courseDelayList = arr
        }
      } else { // 线下课
        // 课程有效期设置 - 默认长期
        obj.validDayType = '2'
      }

      // 课程证书设置
      obj.setCourseCertificate = data.setCourseCertificate

      if(data.setCourseCertificate == '1'){
        // 课程证书
        obj.courseCertificateList = data.courseCertificateList
      }

      // 第二种获得课程证书的方式
      if(data.setCourseCertificate == '2'){
        if (!data.watchCount) {
          return Promise.reject({msg:'请输入数量'})
        }else{
        obj.watchCount = data.watchCount
        }
        if (!data.courseCertifId) {
          return Promise.reject({msg:'请选择证书模板'})
        }else{
        obj.courseCertifId = data.courseCertifId
        }
      }

      // 课程考试设置
      obj.setExam = data.setExam

      // 课程考试设置 - 需要考试
      if(data.setExam == '1'){
        obj.examCertifTempId = data.examCertifTempId
      }

      // 小节自测设置
      obj.setSectionTest = data.setSectionTest

      // 小节自测设置 - 有小节自测
      if(!data.sectionTestCorrectRate && data.sectionTestCorrectRate !== 0){
        return Promise.reject({msg:'小节自测设置请输入通过率',pageName})
      }else{
        obj.sectionTestCorrectRate = data.sectionTestCorrectRate
      }

      // 学习证明名称
      obj.studyCertifName = data.studyCertifName;

      return Promise.resolve(obj)
    },

    // 提交检查Form表单并返回object
    fun_SaveVerific(){
      const data = this.formData
      let obj = new Object()
      let arr = []
      const greater_zero = this.$regular['greater_zero']

      // 课程id
      obj.courseId = data.courseId

      // 免费试看设置
      obj.freeTrialType = data.freeTrialType

      function getTryChapter(){
        let list = []
        function fors(arr){
          arr.forEach((item)=>{
            if(item.childList && item.childList.length>0){
              fors(item.childList)
            }
            if(item.freeTrial == 1){
              list.push(item)
            }
          })
        }
        fors(data.courseCatalogueList)
        return list
      }
      if(data.freeTrialType == '3' && !(getTryChapter() && getTryChapter().length > 0)){ // 当选择免费试看，不允许不选试看章节
        return Promise.reject({msg:'请设置免费试看'})
      }else if(data.freeTrialType == '2'){ // 免费试看分钟为第一章节
        let is = false
        function fors(arr){
          arr.forEach((item)=>{
            if(item.videoFileId && !is){
              item.freeTrial = 1
              is = true
            }
            if(item.childList && item.childList.length>0){
              fors(item.childList)
            }
          })
          return arr
        }
        data.courseCatalogueList = fors(data.courseCatalogueList)
      }


      if(data.freeTrialType == '3'){
        let freeSelctionList = this.getTryChapter()
        obj.courseFreeTrialSectionList = []
        freeSelctionList.forEach(element => {
          obj.courseFreeTrialSectionList.push({
              courseId: data.courseId,
              sectionId: element.sectionId,
            }
          )
        });
      }

      // 免费试看分钟数
      obj.freeTrialMinute = data.freeTrialMinute

      // 课程观看设置 - 首次拖拽
      obj.firstWatchType = data.firstWatchType ? '1' : '0'

      // 课程观看设置 - 再次拖拽
      obj.againWatch = data.againWatch ? '1' : '0'

      // 课程观看设置 - 首次倍速
      obj.firstSpeedType = data.firstSpeedType ? '1' : '0'

      // 课程观看设置 - 再次倍速
      obj.againSpeed = data.againSpeed ? '1' : '0'
      
      // 课程观看设置 - 是否按目录顺序进行观看
      obj.watchByCatalogueOrder = data.watchByCatalogueOrder ? '1' : '0'

      // 课程观看设置 - 观看条件
      obj.watchLimitType = data.watchLimitType

      // 课程观看设置 - 观看条件限制
      obj.courseWatchLimitList = data.courseWatchLimitList

      if(!this.isOffline){ // 线上课程
        // 课程有效期设置 - 固定期限/长期
        obj.validDayType = data.validDayType

        // 课程有效期设置 - 天数
        if(data.validDayType == '1'){
          if(!data.validDays){
            return Promise.reject({msg:'请输入课程有效期设置天数'})
          }else if(!greater_zero.reg.test(data.validDays)){
            return Promise.reject({msg:'课程有效期设置天数'+greater_zero.msg})
          }else{
            obj.validDays = data.validDays
          }

          // 课程有效期设置 - 有效期开始计算节点
          if(data.validStartType == '0'){
            return Promise.reject({msg:'请选择有效期开始计算节点'})
          }else{
            obj.validStartType = data.validStartType
          }

          // 课程有效期设置 - 开始做第n节小节自测起
          if(data.validStartType == '1'){
            if(!data.validStartSectionId){
              return Promise.reject({msg:'请输入课程有效期设置第几节小节自测起计算'})
            }else{
              obj.validStartSectionId = data.validStartSectionId
            }
          }

          // 课程有效期设置 - 观看此章节视频开始算起
          if(data.validStartType == '5'){
            if(!data.validStartSectionId){
              return Promise.reject({msg:'请设置课程有效期计算开始章节'})
            }else{
              obj.validStartSectionId = data.validStartSectionId
            }
          }
        }

        // 课程延期设置
        obj.setDelay = data.setDelay

        // 课程延期设置 - 需要设置课程延期
        if(data.setDelay == '1'){
          arr = []
          data.courseDelayList.forEach((item,index)=>{
            item.sort = index+1
            arr.push(item)
          })
          obj.courseDelayList = arr
        }
      } else { // 线下课
        // 课程有效期设置 - 默认长期
        obj.validDayType = '2' // 默认长期
      }

      // 课程证书设置
      obj.setCourseCertificate = data.setCourseCertificate

      if(data.setCourseCertificate == '1'){
        // 课程证书
        obj.courseCertificateList = data.courseCertificateList
      }

      // 第二种获得课程证书的方式
      if(data.setCourseCertificate == '2'){
        if (!data.watchCount) {
          return Promise.reject({msg:'请输入数量'})
        }else{
        obj.watchCount = data.watchCount
        }
        if (!data.courseCertifId) {
          return Promise.reject({msg:'请选择证书模板'})
        }else{
        obj.courseCertifId = data.courseCertifId
        }
      }

      // 课程考试设置
      obj.setExam = data.setExam

      // 课程考试设置 - 需要考试
      if(data.setExam == '1'){
        if(!data.examCertifTempId){
          return Promise.reject({msg:'课程考试设置请选择需要的证书'})
        }else{
          obj.examCertifTempId = data.examCertifTempId
        }
      }

      // 小节自测设置
      obj.setSectionTest = data.setSectionTest

      // 小节自测设置 - 有小节自测
      if(!data.sectionTestCorrectRate && data.sectionTestCorrectRate !== 0){
        return Promise.reject({msg:'小节自测设置请输入通过率',pageName})
      }else{
        obj.sectionTestCorrectRate = data.sectionTestCorrectRate
      }

      // 学习证明名称
      obj.studyCertifName = data.studyCertifName;

      return Promise.resolve(obj)
    },

    // 组装返现表单数据
    fun_detail(data){
      let obj = {};

      // 课程id
      obj.courseId = data.courseId

      obj.courseCatalogueList = this.courseCatalogueList

      // 免费试看设置
      obj.freeTrialType = String(data.freeTrialType);

      // 免费试看分钟数
      obj.freeTrialMinute = data.freeTrialMinute;

      if(obj.freeTrialType == "3"){
        // 是否为免费试看
        function funFreeTrial(datas) {
          let i = 0;
          data.freeTrialSectionList.forEach((item) => {
            if (item.sectionId == datas.sectionId) {
              i = 1;
            }
          });
          return i;
        }

        // 课程目录
        function fors(arr) {
          if(!arr) {
            return []
          }
          arr.forEach((item) => {
            if (item.childList && item.childList.length > 0) {
              item.childList = fors(item.childList);
            } else {
              item.childList = [];
            }
            item.freeTrial = funFreeTrial(item);
          });
          return arr;
        }
        let list = fors(obj.courseCatalogueList);
        obj.courseCatalogueList = list;
      }

      // 课程观看设置 - 首次拖拽
      obj.firstWatchType = data.firstWatchType == "1";

      // 课程观看设置 - 再次倍速
      obj.againWatch = data.againWatch == "1";

      // 课程观看设置 - 再次拖拽
      obj.firstSpeedType = data.firstSpeedType == "1";

      // 课程观看设置 - 首次倍速
      obj.againSpeed = data.againSpeed == "1";

      // 课程观看设置 - 是否按目录顺序进行观看
      obj.watchByCatalogueOrder = data.watchByCatalogueOrder == "1";

      // 课程观看设置 - 观看条件
      if (data.watchLimitType == "3") {
        // 二者皆可
        this.viewCondition1 = true;
        this.viewCondition2 = true;
      } else if (data.watchLimitType == "1") {
        // 必须通过以下课程的考试
        this.viewCondition1 = true;
      } else if (data.watchLimitType == "2") {
        // 拥有以下课程的考试证书
        this.viewCondition2 = true;
      }
      obj.watchLimitType = data.watchLimitType;

      // 课程观看设置 - 观看条件限制
      obj.courseWatchLimitList = data.watchLimitList;

      // 课程证书设置
      obj.setCourseCertificate = String(data.setCourseCertificate);
      obj.watchCount = data.watchCount;
      obj.courseCertifId = data.courseCertifId;

      if (obj.setCourseCertificate == "1") {
        // 课程证书
        function FunSectionId(id) {
          let val = "";
          function fors(arr) {
            arr.forEach((item) => {
              if (item.childList && item.childList.length > 0) {
                fors(item.childList);
              }
              if (item.sectionId == id) {
                val = item.uid;
              }
            });
          }
          fors(obj.courseCatalogueList);
          return val;
        }
        function CertificateID(arr) {
          if(arr)[
            arr.forEach((item) => {
              item.value = FunSectionId(item.sectionId);
            })
          ]
          return arr;
        }
        obj.courseCertificateList = CertificateID(data.certificateList) || [];
      } else {
        obj.courseCertificateList = [];
      }

      // 课程有效期设置 - 固定期限/长期
      obj.validDayType = data.validDayType;

      // 课程有效期设置 - 天数
      if (data.validDayType == "1") {
        obj.validDays = data.validDays;
      }

      // 课程有效期设置 - 有效期开始计算节点
      obj.validStartType = String(data.validStartType);

      // 课程有效期设置 - 开始做第n节小节自测起
      if (data.validStartType == "1" || data.validStartType == "5") {
        obj.validStartSectionId = data.validStartSectionId;
      }

      // 课程有效期 章节回显
      let _this = this;
      if (data.validStartType == "5") {
        function fors(arr) {
          arr.forEach((item) => {
            if (item.childList && item.childList.length > 0) {
              fors(item.childList);
            }
            if (item.sectionId == data.validStartSectionId) {
              _this.validStartSectionName = item.sectionName;
            }
          });
        }
        fors(obj.courseCatalogueList);
      }

      // 课程延期设置
      obj.setDelay = String(data.setDelay);

      // 课程延期设置 - 需要设置课程延期
      if (data.setDelay == "1") {
        obj.courseDelayList = data.delayList || [];
      } else {
        obj.courseDelayList = [];
      }

      // 课程考试设置
      obj.setExam = String(data.setExam);

      // 课程考试设置 - 需要考试
      if (data.setExam == "1") {
        obj.examCertifTempId = data.examCertifTempId ? data.examCertifTempId : undefined;
      }

      // 小节自测设置
      obj.setSectionTest = String(data.setSectionTest);

      // 小节自测设置 - 有小节自测
      obj.sectionTestCorrectRate = data.sectionTestCorrectRate;

      // 学习证明名称
      obj.studyCertifName = data.studyCertifName;

      return obj
    },

    // 暂存
    onStorageClick(){
      this.loadingBtn = true
      Promise.all([
        this.fun_StorageVerific()
      ]).then(res=>{
        let obj = res[0]
        obj.saveType = 1
        this.onSubmitClick(obj)
      }).catch(err=>{
        if(err){
          this.$message.warning(err.msg)
        }else{
          console.error(err)
        }
        this.loadingBtn = false
      })
    },
    onSaveClick(){
      this.loadingBtn = true
      Promise.all([
        this.fun_SaveVerific()
      ]).then(res=>{
        let obj = res[0]
        obj.saveType = 2
        this.onSubmitClick(obj)
      }).catch(err=>{
        if(err){
          this.$message.warning(err.msg)
        }else{
          console.error(err)
        }
        this.loadingBtn = false
      })
    },

    onSubmitClick(obj){
      this.loadingBtn = true
      let url = ''
      if(this.saveType){
        url = "/hxclass-management/course/updateCourseWatchConfig"
      } else {
        url = "/hxclass-management/course/saveCourseWatchConfig"
      }
      this.$message.loading({ content: "课程信息正在存储...", duration: 0 });
      this.$ajax({
        url: url,
        method: "POST",
        params: obj,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success( (obj.saveType == 1 ? '暂存' : '保存') + '成功!')
          this.loadingBtn = false
          this.saveType = true
          // 向父页面更新目录修改状态
          this.$parent.onChildInit('watchConfig', obj.saveType);
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 查询详情
    getFormDetail(){
      this.loadingBtn = true
      this.$message.loading({ content: "正在查询详情...", duration: 0 });
      this.$ajax({
        url: "/hxclass-management/course/getCourseWatchConfig",
        params: {
          courseId: this.formData.courseId
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.saveType = res.data.saveType ? true : false
          // 开班设置
          this.setOpenClass = String(res.data.setOpenClass);
          this.openTimeList = res.data.openTimeList;

          if(this.saveType){
            this.formData = this.fun_detail(res.data);
          }

          this.isOffline = res.data.courseClass == 2  // 是否线下课
          
          this.$message.success("查询成功!");
        } else {
          this.$message.error(res.message);
        }
        this.loadingBtn = false
      });
    },

    // 获取目录列表
    getContentsList(){
      this.loadingBtn = true
      this.$ajax({
        url: "/hxclass-management/course/getManageSectionTree",
        params: {
          courseId: this.formData.courseId,
        },
      })
      .then((res) => {
        if (res.code == 200 && res.success) {
          if(res.data.length){ 
            // 课程目录
            function fors(arr) {
              if(!arr) {
                return []
              }
              arr.forEach((item) => {
                if (item.childList && item.childList.length > 0) {
                  item.childList = fors(item.childList);
                } else {
                  item.childList = [];
                }
                item.uid = uuid();
                let array = [];
                item.teacherList.forEach((items) => {
                  array.push({
                    id: items.teacherId,
                  });
                });
                item.teacherIdList = array;
              });
              return arr;
            }
            let list = fors(res.data);

            this.formData.courseCatalogueList = list
            this.courseCatalogueList = list

            // 章节是否有小节自测
            this.SectionTestLength = this.returnSectionTestLength();
          } else {
            this.formData.courseCatalogueList = []
            this.courseCatalogueList = []
          }
        } else {
          this.$message.error(res.message);
        }
        this.getFormDetail();
      })
      .catch((err) => {
        
      });
    },

    // 查询证书模板下拉框
    getCertificateTemplate(){
      this.$ajax({
        url: '/hxclass-management/certif-temp/select/option',
        params: {
          // type: 1
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.examCertifTempList = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getCertificateTemplate() // 查询证书模板下拉框
    this.getCurriculumCertifTemplate() // 查询课程证书模板下拉框
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      this.formData.courseId = Number(this.$route.query.id)
      this.getContentsList();
    });
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
@import "./style.less";
</style>
